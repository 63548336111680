import React, { useContext, useState } from "react";

const PassiveLoginModal = ({ onSuccess }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        if(loading){
            return;
        }
        e.preventDefault();
        setLoading(true);
        console.log(email, password)
        try {
            const response = await fetch("https://api.dev.joinpassive.com/login", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });
            if (response.status === 200) {
                const data = await response.json();
                onSuccess(data.id);
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (error) {
            setError("Something went wrong.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" ,overflow: "hidden" , borderRadius: "12px" }}>
            <div style={{ backgroundColor: "#fff", padding: "2rem", borderRadius: "12px", width: "350px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" ,width:"100%" }}>
                    <div>
                        <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Email</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                            style={{ width: "100%", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "8px", outline: "none" }}
                        />
                    </div>
                    <div>
                        <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Password</label>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            style={{ width: "100%", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "8px", outline: "none" }}
                        />
                    </div>
                </div>
                <div 
                    type="submit" 
                    onClick={handleSubmit}
                    style={{ width: "100%", backgroundColor: loading ? "#9ca3af" : "#064274", color: "#fff", padding: "0.5rem", borderRadius: "8px", border: "none", cursor: "pointer", transition: "background 0.3s" , marginTop: "2rem" ,textAlign: "center" }}
                >
                    {loading ? "Loading..." : "Login"}
                </div>
            </div>
        </div>
    );
};

export default PassiveLoginModal;