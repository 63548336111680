import React, { useContext, useState } from "react";
import { ButtonRow, ConfirmButton } from "./passive-modality.styles";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
import { PropertyInput } from "./passive-modality.styles";

const PropertySubmissionModal = ({ property, onClose, dealId, amount }) => {
    const [formData, setFormData] = useState({
        address_line_1: property.address,
        city: property.city,
        state: property.state,
        zip_code: property.zip,
        amount: amount,
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const api = useContext(ApiContext);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleConfirm = async () => {
        try {
            const payload = { dealId: dealId };
            const response = await api.post('/api/passive/property', payload);
            if (response?.message) {
                toast.success("Deal Connected with Passive successfully");
                onClose();
            } else {
                toast.error("Please contact support");
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ display: "flex", backgroundColor: "#fff",  borderRadius: "12px", width: "700px" }}>

                <div style={{ flex: 1 }}>
                    <form style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div>
                            <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Address</label>
                            <PropertyInput type="text" name="address_line_1" value={formData.address_line_1} onChange={handleChange} required disabled />
                        </div>
                        <div>
                            <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>City</label>
                            <PropertyInput type="text" name="city" value={formData.city} onChange={handleChange} required disabled />
                        </div>
                        <div>
                            <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>State</label>
                            <PropertyInput type="text" name="state" value={formData.state} onChange={handleChange} required disabled />
                        </div>
                        <div>
                            <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Zip Code</label>
                            <PropertyInput type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} required disabled />
                        </div>
                        <div>
                            <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Amount</label>
                            <PropertyInput type="number" name="amount" value={formData.amount} onChange={handleChange} required disabled />
                        </div>
                        {error && <div style={{ color: "red" }}>{error}</div>}
                        {success && <div style={{ color: "green" }}>{success}</div>}
                    </form>
                    <ButtonRow>
                        <ConfirmButton onClick={handleConfirm} style={{ width: "100%", backgroundColor: "#064274", color: "#fff", padding: "0.75rem",marginTop:"2rem", borderRadius: "8px", border: "none", cursor: "pointer", transition: "background 0.3s" }}>
                            Confirm
                        </ConfirmButton>
                    </ButtonRow>
                </div>
            </div>
        </div>
    );
};

export default PropertySubmissionModal;
