import styled from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const Canvas = styled.div`
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: -webkit-fill-available;
`;

export const DateDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
`;

export const AgentName = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Title/Small */
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AgentCard = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border: 1px solid #f3f3f3;
    width: fit-content;
    border-radius: 10px;
    padding: 15px;
    min-width: 300px;
    align-items: center;
`;

export const LeaseCard = styled(AgentCard)`
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    border: ${props => !props.leaseDoc && 'unset' };
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    aspect-ratio : 1 / 1;
    cursor: pointer;
    background: ${props => props.selected? '#F0F8FF': props.reminder? '#064274':'#f7f7f7'};
    border: ${props => props.selected? '1px solid #064274':"1px solid #f7f7f7"};
    // padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    
    .svg {
        height: 25px;
        width: 25px;
    }
`;

export const IconDiv = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;


export const SubscriptText = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const FlexDiv = styled.div`
    display: flex;
    gap: 15px;
`;

export const FlexDivColumn = styled(FlexDiv)`
    flex-direction: column;
`;

export const FlexDivCenter = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
`;
export const DateText = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;


    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const LeasePackageDiv = styled.div`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    border: 1px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    color: #064274;
    justify-content: space-between;

    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;
    height: fit-content;
    cursor: pointer;
    width: -webkit-fill-available;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const CreateLeaseDiv = styled.div`
    border-radius: 75px;
    border: 1px dashed var(--Light-Grey, #A4A4A4);
    display: flex;
    cursor: pointer;
    padding: 4px 12px;
    align-items: center;
    gap: var(--4, 4px);
    width: fit-content;
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;

    span {
        color: var(--Light-Grey, #A4A4A4);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;

export const ConnectBuildiumDiv = styled(CreateLeaseDiv)`
    color: var(--F7, #f7f7f7);
    background-color: var(--Blue-100, #064274);
    border: none;

    ${(props) =>
        props.disabled &&
        `
        opacity: 0.6;
        cursor: not-allowed;
        background-color: #cccccc;
        pointer-events: none;
    `}
`;

export const BuildiumConnectedBadge = styled.div`
    background-color: #f7f7f7;
    color: #1ccb00;
    border: 1px solid #1ccb00;
    width: -webkit-fill-available;
    border-radius: 75px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: var(--4, 4px);
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const Th = styled.th`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableRow = styled.tr`
    &:not(:last-child) {
        td {
            border-bottom: 1px solid #f1f1f1; 
        }
        
    }
`;

export const TableCellWrapper = styled.div`
    padding: 5px 0; /* This replaces the vertical cell padding and adds space between rows */
`;

export const ApplicantName = styled.td`
    color: var(--Greyish-Black, #323232);

    /* Body/Large */
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;

const color = ({status}) => {
    if(status === "Pending"){
        return `    
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    } else if(status === "In Review" || status === "Initiated" ){
        return `    
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }
    else if (status === 'Signed' || status === "Completed"){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `; 
    }
}

export const StatusBadge = styled.div`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 500; // Medium font weight
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    width: fit-content;
    justify-content: center; // Horizontal align to center
    text-edge: cap;
    min-width: 100px;
`;

export const ContactDiv = styled.button`
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: var(--F7, #F7F7F7);
    border: none;
    cursor: pointer;
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`;

export const PaymentAmount = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;

export const EmailName = styled.td`
  color: var(--Greyish-Black, #323232);

    /* Body/Large */
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ConnectPassiveDiv = styled(CreateLeaseDiv)`
    color: var(--F7, #f7f7f7);
    background-color: var(--Blue-100, #064274);
    border: none;

    ${(props) =>
        props.disabled &&
        `
        opacity: 0.6;
        cursor: not-allowed;
        background-color: #cccccc;
        pointer-events: none;
    `}
`;