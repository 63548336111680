import React, { useEffect, useState } from "react";
import FormInput from '../../form-input/form-input';
import Select from 'react-select';
import moment from "moment";
import { 
    NextButton,
    Section, 
    WrapDiv,
    SectionTitle,
    GapDiv,
    PreviousButton,
    SelectDiv,
    Label
} from "../shared-styles";
import { Country, State, City }  from 'country-state-city';
import { FlexDiv } from "../../form-input/form-input.styles";


const RentalHistory = ({ applicationForm, setApplicationData, setIsFocus }) => {

    const [errors, setErrors] = useState({});
    const [countries , setCountries] = useState([]);
    const [states , setStates] = useState([]);
    const [cities , setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        setCountries(Country.getAllCountries())
        setStates(State.getStatesOfCountry('US'))
        setCities(City.getCitiesOfState('US', 'MA'))
    }, [])

    useEffect(()=>{
        setSelectedState('');
        setSelectedCity('');
        setStates(State.getStatesOfCountry(selectedCountry.isoCode))
        setCities(City.getCitiesOfState(selectedCountry.isoCode))
    }, [selectedCountry])

    useEffect(()=>{
        setSelectedCity('');
        setCities(City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode))
    }, [selectedState])

    const verifyInputs = () => {
        let newErrors = {};
        if (!applicationForm?.currentAddress) newErrors.currentAddress = 'Address is required';
        if (!applicationForm?.currentCity) newErrors.currentCity = 'City is required';
        if (!applicationForm?.currentState) newErrors.currentState = 'State is required';
        if (!applicationForm?.currentCountry) newErrors.currentCountry = 'Country is required';
        if (!applicationForm?.currentPincode) newErrors.currentPincode = 'Pin Code is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleChange = (name, value) => {
        if (name === 'currentCountry') {
            setSelectedCountry(value);
            value = value.name
        }
        else if (name === 'currentState') {
            setSelectedState(value);
            value = value.isoCode
        }
        else if (name === 'currentCity') {
            setSelectedCity(value);
            value = value.name
        }
        setApplicationData(prev => ({
            ...prev,
            applicationForm: {
                ...prev?.applicationForm,
                [name]: value
            }
        }))
    }

    const handleNext = () => {
        if(verifyInputs()){
            setIsFocus('Employment Details')
        }
    }

    return (
        <>
            <Section>
                <SectionTitle>Current Residence</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Present Address"
                        type = 'text'
                        defaultValue={applicationForm?.currentAddress}
                        handleChange={(value) => handleChange('currentAddress', value)}
                        isRequired={true}
                        flexBasis={true}
                        error={errors.currentAddress}
                    />
                </WrapDiv>
                <WrapDiv>
                    <SelectDiv>
                        <FlexDiv>
                            <Label>Country</Label>
                            {errors.currentCountry && <span style={{color: 'red'}}>{errors.currentCountry}</span>}
                        </FlexDiv>
                        <Select
                            name="country"
                            required={true}
                            error={errors.currentCountry}
                            options={countries}
                            value={selectedCountry}
                            getOptionLabel={(option) => option.name}  // label based on name
                            getOptionValue={(option) => option.isoCode}  // unique value based on id"
                            onChange={(selectedOption)=>handleChange('currentCountry', selectedOption)}
                        />
                    </SelectDiv>
                    <SelectDiv>
                        <FlexDiv>
                            <Label>State</Label>
                            {errors.currentState && <span style={{color: 'red'}}>{errors.currentState}</span>}
                        </FlexDiv>
                        <Select
                            name="state"
                            required={true}
                            options={states}
                            value={selectedState}
                            getOptionLabel={(option) => option.name}  // label based on name
                            getOptionValue={(option) => option.name}  // unique value based on id"
                            onChange={(selectedOption)=>handleChange('currentState', selectedOption)}
                        />
                    </SelectDiv>
                    <SelectDiv>
                        <FlexDiv>
                            <Label>City</Label>
                            {errors.currentCity && <span style={{color: 'red'}}>{errors.currentCity}</span>}
                        </FlexDiv>
                        <Select
                            name="city"
                            required={true}
                            options={cities}
                            value={selectedCity}
                            getOptionLabel={(option) => option.name}  // label based on name
                            getOptionValue={(option) => option.name}  // unique value based on id"
                            onChange={(selectedOption)=>handleChange('currentCity', selectedOption)}
                        />
                    </SelectDiv>
                    <FormInput
                        label = "Pin Code"
                        type = 'phone'
                        defaultValue={applicationForm?.currentPincode}
                        isRequired={true}
                        handleChange={(value) => handleChange('currentPincode', value)}
                        flexBasis={true}
                        error={errors.currentPincode}
                    />
                </WrapDiv>
                <WrapDiv>
                    <FormInput
                        id="From"
                        label="Move In Date"
                        type="date"
                        value={applicationForm?.currentMoveIn}
                        handleChange={(value) => handleChange('currentMoveIn', moment(value).format("MM/DD/YYYY"))}
                        isRequired={false}
                        flexBasis={true}
                    />
                    <FormInput
                        id="To"
                        label="Move Out Date"
                        type="date"
                        value={applicationForm?.currentMoveOut}
                        handleChange={(value) => handleChange('currentMoveOut', moment(value).format("MM/DD/YYYY"))}
                        isRequired={false}
                        flexBasis={true}
                    />
                    </WrapDiv>  
            </Section>
            <Section>
                <SectionTitle>Current Landlord</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Name"
                        type = 'email'
                        defaultValue={applicationForm?.landlordName}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordName', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Address"
                        type = 'phone'
                        defaultValue={applicationForm?.landlordAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Phone"
                        type = 'phone'
                        defaultValue={applicationForm?.landlordPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Former Landlord</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Name"
                        type = 'email'
                        defaultValue={applicationForm?.formerLandlordName}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordName', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Address"
                        type = 'phone'
                        defaultValue={applicationForm?.formerLandlordAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Phone"
                        type = 'phone'
                        defaultValue={applicationForm?.formerLandlordPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <GapDiv>
                <PreviousButton onClick = {() => setIsFocus('Personal Details')}>Previous</PreviousButton>
                <NextButton onClick={handleNext}>Continue</NextButton>       
            </GapDiv>  
        </>
    )
}

export default RentalHistory