import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { 
    ModalOverlay, 
    ModalWrapper, 
    CloseButton, 
    SpaceDiv, 
    HeaderContainer, 
    TitleHeader, 
    ModalBody 
} from "./passive-modality.styles";
import  PassiveLoginModal  from "./passive-login";
import  PassiveVerifyOtpModal  from "./passive-verify-otp";
import  PropertySubmissionModal  from "./passive-property";
import { ApiContext } from "../../context/apiContext";

const PassiveModality = ({ dealId, property, amount, isOpen, onClose }) => {
    const [step, setStep] = useState("checking");
    const [user_id, setUser_id] = useState(null);
    const api = useContext(ApiContext)
    useEffect(() => {
        const checkToken = async () => {
            try {
                const res = await api.get('/api/passive/token');
                if(res.validToken) {
                    setStep("property");
                } else {
                    setStep("login");
                }
            } catch (error) {
                console.error('Cannot Connect', error)
            }
        }
        checkToken();
       
    }, []);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalOverlay>
            <ModalWrapper>
                <HeaderContainer>
                    <SpaceDiv>
                        <TitleHeader>Send to Passive</TitleHeader>
                        <div><CloseButton onClick={onClose}>X</CloseButton></div>
                    </SpaceDiv>
                </HeaderContainer>

                <ModalBody>
                    {step === "login" && <PassiveLoginModal onSuccess={(id) => {setUser_id(id); setStep("otp")}} />}
                    {step === "otp" && <PassiveVerifyOtpModal onSuccess={() => setStep("property")} user_id={user_id} />}
                    {step === "property" && <PropertySubmissionModal property={property} dealId={dealId} amount={amount} onClose={onClose} />}
                </ModalBody>
            </ModalWrapper>
        </ModalOverlay>,
        document.getElementById("modal-root")
    );
};

export default PassiveModality;
