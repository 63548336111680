import React, { useState, useEffect, useContext } from "react";
import { ApiContext } from "../../context/apiContext";

const PassiveVerifyOtpModal = ({ onSuccess, user_id = null }) => {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [resendSuccess, setResendSuccess] = useState("");
    const [resendTimer, setResendTimer] = useState(60);
    const api = useContext(ApiContext);

    const handleVerify = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        setResendSuccess("");
        try {
            const response = await fetch("https://api.dev.joinpassive.com/totp/verify", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ code: otp, user_id: user_id }),
            });

            if (response.status === 200) {
                const data = await response.json();
                localStorage.setItem("passiveToken", data.access_token);
                await api.post('/api/passive/token', { token: data.access_token });
                onSuccess(user_id);
            } else {
                throw new Error("Invalid OTP");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (resendTimer > 0) {
            const interval = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [resendTimer]);

    const handleResendOtp = async () => {
        setError("");
        setResendSuccess("");
        try {
            const response = await fetch("https://api.dev.joinpassive.com/totp/resend", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user_id }),
            });

            if (response.ok) {
                setResendSuccess("OTP has been resent. Please check your phone.");
                setResendTimer(60);
            } else {
                const errorData = await response.json();
                setError(errorData.message || "Failed to resend OTP.");
            }
        } catch (error) {
            setError("Something went wrong. Please try again.");
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center",overflow: "hidden", justifyContent: "center" }}>
            <div style={{ backgroundColor: "#fff", padding: "2rem", borderRadius: "12px", width: "350px" }}>
                <form onSubmit={handleVerify} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <div>
                        <label style={{ display: "block", color: "#4b5563", marginBottom: "0.25rem" }}>Enter OTP</label>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            style={{ width: "calc(100% - 1rem)", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "8px", outline: "none" }}
                        />
                    </div>
                    {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
                    {resendSuccess && <div style={{ color: "green", textAlign: "center" }}>{resendSuccess}</div>}
                    <button
                        type="submit"
                        disabled={loading}
                        style={{ width: "100%", backgroundColor: loading ? "#9ca3af" : "#3b82f6", color: "#fff", padding: "0.75rem", borderRadius: "8px", border: "none", cursor: "pointer", transition: "background 0.3s" }}
                    >
                        {loading ? "Verifying..." : "Verify OTP"}
                    </button>
                </form>
                <button
                    disabled={resendTimer > 0}
                    onClick={handleResendOtp}
                    style={{
                        width: "100%",
                        marginTop: "10px",
                        backgroundColor: resendTimer > 0 ? "#9ca3af" : "#10b981",
                        color: "#fff",
                        padding: "0.75rem",
                        borderRadius: "8px",
                        border: "none",
                        cursor: resendTimer > 0 ? "not-allowed" : "pointer",
                        transition: "background 0.3s",
                    }}
                >
                    {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
                </button>
            </div>
        </div>
    );
};

export default PassiveVerifyOtpModal;
